import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_app_bar = _resolveComponent("app-bar")!
  const _component_left_side_bar = _resolveComponent("left-side-bar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog),
    _createVNode(_component_app_bar),
    _createVNode(_component_left_side_bar),
    _createVNode(_component_v_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_breadcrumbs, { items: _ctx.breadcrumbs }, {
          divider: _withCtx(() => [
            _createVNode(_component_v_icon, { icon: "mdi-chevron-right" })
          ]),
          _: 1
        }, 8, ["items"]),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ]))
}
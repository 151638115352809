
import { defineComponent } from 'vue'

const DEFAULT_LAYOUT = 'admin'

export default defineComponent({
  name: 'App',

  computed: {
    layout (): string {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout'
    }
  }
})


import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForeignField',
  props: {
    routerName: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => [],
      required: false
    },
    itemValue: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      savedItem: null
    }
  },
  computed: {
    itemsCombined () {
      if (this.savedItem === null || this.items.includes(this.savedItem)) {
        return this.items
      }
      return [...this.items, this.savedItem]
    }
  },
  watch: {
    items: function (value) {
      for (const item of value) {
        if (item[this.itemValue] === this.$attrs.modelValue) {
          this.savedItem = item
          break
        }
      }
    }
  }
})

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mb-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, { density: "compact" }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.name) + _toDisplayString(_ctx.isChanged ? '*' : ''), 1)
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (_ctx.compact)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  depressed: "",
                  onClick: _withModifiers(_ctx.toggleEditMode, ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.rawEditMode ? 'Schema' : 'Raw'), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (_ctx.rawEditMode)
            ? (_openBlock(), _createBlock(_component_v_textarea, {
                key: 0,
                modelValue: _ctx.rawValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rawValue) = $event)),
                rules: [_ctx.json()],
                filled: "",
                label: "Raw Json",
                "error-messages": _ctx.errorMessages
              }, null, 8, ["modelValue", "rules", "error-messages"]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.currentSchema, (item) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (item.enum.length)
                    ? (_openBlock(), _createBlock(_component_v_select, {
                        key: item.name,
                        modelValue: _ctx.currentValues[item.name],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.currentValues[item.name]) = $event),
                        items: item.enum,
                        label: item.title,
                        rules: item.rules
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "label", "rules"]))
                    : (item.type === 'string')
                      ? (_openBlock(), _createBlock(_component_v_text_field, {
                          key: item.name,
                          modelValue: _ctx.currentValues[item.name],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.currentValues[item.name]) = $event),
                          label: item.title,
                          rules: item.rules,
                          filled: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "rules"]))
                      : (item.type === 'integer' || item.type === 'number')
                        ? (_openBlock(), _createBlock(_component_v_text_field, {
                            key: item.name,
                            modelValue: _ctx.currentValues[item.name],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.currentValues[item.name]) = $event),
                            label: item.title,
                            rules: item.rules,
                            "single-line": "",
                            type: "number"
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "rules"]))
                        : (item.type === 'boolean')
                          ? (_openBlock(), _createBlock(_component_v_checkbox, {
                              key: item.name,
                              modelValue: _ctx.currentValues[item.name],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.currentValues[item.name]) = $event),
                              label: item.title,
                              rules: item.rules
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "rules"]))
                          : _createCommentVNode("", true)
                ], 64))
              }), 256))
        ]),
        _: 1
      }),
      (!_ctx.compact || (_ctx.compact && _ctx.rawEditMode))
        ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              (_ctx.rawEditMode)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    color: "success",
                    depressed: "",
                    onClick: _withModifiers(_ctx.restoreSavedValue, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Restore saved ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.rawEditMode)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    color: "success",
                    depressed: "",
                    onClick: _withModifiers(_ctx.beautifyValue, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Beautify ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (!_ctx.compact)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    color: "success",
                    depressed: "",
                    onClick: _withModifiers(_ctx.toggleEditMode, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.rawEditMode ? 'Schema Editor' : 'Raw Json Editor'), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
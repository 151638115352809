
import { defineComponent } from 'vue'
import AppBar from '@/components/admin/AppBar.vue'
import LeftSideBar from '@/components/admin/LeftSideBar.vue'
import Dialog from '@/components/admin/Dialog.vue'
import { Breadcrumb } from '@/router'

export default defineComponent({
  components: { AppBar, LeftSideBar, Dialog },
  computed: {
    // TODO nove breadcrumbs do component
    breadcrumbs (): Breadcrumb[] {
      const result = []
      for (const item of this.$route.meta.breadcrumbs || []) {
        result.push(this.breadcrumbAttrs(item))
      }
      return result
    }
  },
  methods: {
    breadcrumbAttrs (item: Breadcrumb): Breadcrumb {
      const result = {
        text: item.text,
        replace: false
      } as Breadcrumb
      if (!item.to && !item.href) {
        result.href = '#'
        result.disabled = true
      }
      if ('to' in item) {
        // TODO copy `to`, delete `href`
        result.href = this.$router.resolve(item.to || {}).path
        result.disabled = false
      }
      if ('disabled' in item) {
        result.disabled = item.disabled
      }
      return result
    }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_autocomplete, _mergeProps(_ctx.$attrs, {
      placeholder: "Start typing to Search",
      "item-value": _ctx.itemValue,
      items: _ctx.itemsCombined
    }), _createSlots({ _: 2 }, [
      (_ctx.$attrs.modelValue)
        ? {
            name: "append",
            fn: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: "mdi-link",
                variant: "text",
                title: "Go to",
                to: {name: _ctx.routerName, params: {id: _ctx.$attrs.modelValue}}
              }, null, 8, ["to"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["item-value", "items"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog),
    _createVNode(_component_v_main, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ]))
}